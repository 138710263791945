import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { useWpHomepageSelectionNew } from "../../../hooks/useWpHomepage"
import { motion, AnimatePresence } from "framer-motion"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const marqueeHorAnimation = () => keyframes`
  0% {
    transform: translateX(0%)
  }
  100% {
    
    transform: translateX(-200%)
  }
`

const Container = styled(motion.section)`
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 10px;
  left: 0;

  .moving-parts {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    white-space: nowrap;
    width: fit-content;
    height: 100%;
    display: flex;
    position: absolute;
    left: 100%;
    top: 0;

    animation-play-state: ${props => (props.$isplaying ? "running" : "paused")};
    animation-name: ${marqueeHorAnimation()};
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &:nth-of-type(2) {
      animation-delay: 20s;
    }

    .miniature-color-container {
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .gatsby-image-wrapper {
        pointer-events: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        min-width: 80px;
        margin-right: 5px;
        margin-left: 5px;
        height: 100%;

        img {
          pointer-events: none; // for Android
          -webkit-touch-callout: none; // for iOS
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      &.portrait {
        display: flex;
        align-items: flex-end;
        .gatsby-image-wrapper {
          min-width: 50px;
        }
      }

      &.active-mobile-miniature {
        .gatsby-image-wrapper {
          picture {
            display: none;
          }
          img {
            display: none;
          }
          div {
            background-color: var(--gv-blue);
            height: 100%;
            max-width: 100% !important;
          }
        }
      }
    }
  }
`

const Gallery = styled.section`
  // border: 1px solid red;

  z-index: 0;
  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 80px);
  width: 100%;
  .gallery-image-container {
    height: calc(100%);
  }
  .single-gallery-image {
    position: absolute;
    // border: 1px solid black;

    padding-left: 10px;
    padding-right: 10px;

    background: white;

    padding-top: 7px;

    height: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .texts-wrapper {
      p {
        margin: 0;
      }
    }

    .gallery-main-row {
      margin-bottom: 40px;
      display: flex;
      align-items: center;

      .gatsby-image-wrapper {
        &.portrait {
          height: calc(100% - 85px);
          img {
            object-fit: contain !important;
          }
          margin-left: calc(100vw / 7);
          margin-right: calc(100vw / 7);
        }
      }
    }
  }
`

const DURATION = 40
const MobileMarquee = ({ selection, imageGallery }) => {
  const [isTapping, setIsTapping] = useState(false)
  const [whichActive, setWchichActive] = useState(null)
  const totalCount = selection.length
  return (
    <Container
      $isplaying={!isTapping}
      onTapStart={() => setIsTapping(!isTapping)}
      // onTap={() => setIsTapping(false)}
    >
      <Gallery>
        <div className="gallery-image-container">
          {imageGallery.map((selection, index) => {
            let isVertical = false
            if (
              selection.featuredImage?.node.localFile.childImageSharp
                .gatsbyImageData.width >
              selection.featuredImage?.node.localFile.childImageSharp
                .gatsbyImageData.height
            ) {
              isVertical = false
            } else {
              isVertical = true
            }

            return (
              <AnimatePresence
                key={`animatePresence_mobile_${index}`}
                exitBeforeEnter={false}
              >
                {whichActive === index && isTapping && (
                  <motion.div
                    className="single-gallery-image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="texts-wrapper">
                      <p className="serif-size">{`${selection.categories.nodes[0].name},  ${selection.title}`}</p>
                      <p className="serif-size">{`${selection.project.specs},`}</p>
                      <p className="serif-size">{`${selection.project.date}`}</p>
                    </div>
                    <div className="gallery-main-row">
                      <GatsbyImage
                        className={`${isVertical ? "portrait" : "landscape"}`}
                        image={getImage(selection.featuredImage.node.localFile)}
                        alt=""
                      />
                    </div>
                    <div
                      className={`counter sans-size ${
                        isVertical ? "portrait" : "landscape"
                      }`}
                    >
                      {index}/{totalCount}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            )
          })}
        </div>
      </Gallery>
      <div className="moving-parts">
        {selection.map((selected, index) => {
          // console.log(selected.featuredImage.node.localFile)
          let isVertical = false
          if (
            selected.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.width >
            selected.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.height
          ) {
            isVertical = false
          } else {
            isVertical = true
          }
          return (
            <motion.div
              key={`${index}`}
              className={`${
                whichActive === index && isTapping && `active-mobile-miniature`
              } miniature-color-container ${
                isVertical ? "portrait" : "landscape"
              }`}
              onTapStart={() => setWchichActive(index)}
              onContextMenu={event => event.preventDefault()}
            >
              <GatsbyImage
                onContextMenu={event => event.preventDefault()}
                image={getImage(selected.featuredImage?.node.localFile)}
                alt=""
              />
            </motion.div>
          )
        })}
      </div>
      <div className="moving-parts">
        {selection.map((selected, index) => {
          // console.log(selected.featuredImage.node.localFile)
          let isVertical = false
          if (
            selected.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.width >
            selected.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.height
          ) {
            isVertical = false
          } else {
            isVertical = true
          }
          return (
            <motion.div
              key={`2_${index}`}
              className={`${
                whichActive === index && isTapping && `active-mobile-miniature`
              } miniature-color-container ${
                isVertical ? "portrait" : "landscape"
              }`}
              onTapStart={() => setWchichActive(index)}
            >
              <GatsbyImage
                image={getImage(selected.featuredImage?.node.localFile)}
                alt=""
              />
            </motion.div>
          )
        })}
      </div>
    </Container>
  )
}

export default MobileMarquee
