import React, { useRef, useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from "framer-motion"
import "./SimpleVerticalMarquee.scss"

const Playground = styled(motion.div)`
  //   border: 1px solid violet;
  position: fixed;
  width: calc(100vw / 12);
  height: 100vh;
  right: 10px;
  top: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;

  // overflow-y: scroll;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
`

const marqueeAnimation = () => keyframes`
0% {
    top:100vh;
  }
  100% {
    transform: translateY(-200%); 
  }
`

const Marquee = styled.div`
  text-align: right;
  //   border: 1px solid blue;

  position: absolute;
  z-index: 1;
  top: 100vh;

  display: flex;
  flex-direction: column;

  animation-play-state: ${props => (props.isPlaying ? "running" : "paused")};
  animation-delay: ${props =>
    props.isOffset ? `${props.duration / 2}s` : "0"};
  animation-name: ${marqueeAnimation()};
  animation-duration: ${props => (props.duration ? `${props.duration}s` : "1")};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`
const Square = styled(motion.div)`
  margin-bottom: 5px;

  &.miniature-blue {
    .gatsby-image-wrapper {
      picture {
        display: none;
      }
      img {
        display: none;
      }
      div {
        background-color: var(--gv-blue);
      }
    }
  }
`
const SimpleVerticalMarquee = ({ duration, selection, imageGallery }) => {
  const marqueeContainer = useRef(null)
  const content = useRef(null)
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setWidth(content.current.getBoundingClientRect().width)
  }, [width])

  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    setIsPlaying(true)
  }, [])

  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.3 } },
  }
  const totalCount = selection.length
  const [whichSelected, setWhichSelected] = useState(0)
  return (
    <>
      <div className="image-gallery-container">
        {imageGallery.map((selection, index) => {
          let isVertical = false
          if (
            selection.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.width >
            selection.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.height
          ) {
            isVertical = false
          } else {
            isVertical = true
          }

          return (
            <AnimatePresence
              key={`animatePresence_${index}`}
              exitBeforeEnter={false}
            >
              {whichSelected === index && !isPlaying && (
                <motion.div
                  className="single-gallery-image"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="gallery-main-row">
                    <div
                      className={`counter sans-size ${
                        isVertical ? "portrait" : "landscape"
                      }`}
                    >
                      {index + 1}/{totalCount}
                    </div>
                    <div className="gatsby-image-custom-wrapper">
                      <GatsbyImage
                        className={`${isVertical ? "portrait" : "landscape"}`}
                        image={getImage(selection.featuredImage.node.localFile)}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="texts-wrapper">
                    <p className="serif-size">{`${selection.categories.nodes[0].name}, ${selection.title}, ${selection.project.specs}, ${selection.project.date}`}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          )
        })}
      </div>
      <motion.div variants={animations} initial="initial" animate="animate">
        <Playground ref={marqueeContainer}>
          <Marquee
            ref={content}
            isPlaying={isPlaying}
            isOffset={false}
            duration={duration}
          >
            {selection.map((selected, index) => {
              return (
                <Square
                  key={index}
                  className={`miniature ${
                    whichSelected === index && !isPlaying && "miniature-blue"
                  }`}
                  onHoverStart={() => {
                    setIsPlaying(false)
                    setWhichSelected(index)
                  }}
                  onHoverEnd={() => {
                    setIsPlaying(true)
                  }}
                  onTapStart={() => {
                    setIsPlaying(false)
                    setWhichSelected(index)
                  }}
                  onTap={() => setIsPlaying(true)}
                >
                  {" "}
                  <GatsbyImage
                    image={getImage(selected.featuredImage?.node.localFile)}
                    alt=""
                  ></GatsbyImage>
                </Square>
              )
            })}
          </Marquee>
          <Marquee
            drag
            isPlaying={isPlaying}
            isOffset={true}
            className="offsetted"
            duration={duration}
          >
            {selection.map((selected, index) => {
              return (
                <Square
                  key={`offset_${index}`}
                  className={`miniature offsetted ${
                    whichSelected === index && !isPlaying && "miniature-blue"
                  }`}
                  onHoverStart={() => {
                    setIsPlaying(false)
                    setWhichSelected(index)
                  }}
                  onHoverEnd={() => {
                    setIsPlaying(true)
                  }}
                  onTapStart={() => {
                    setIsPlaying(false)
                    setWhichSelected(index)
                  }}
                  onTap={() => setIsPlaying(true)}
                >
                  <GatsbyImage
                    image={getImage(selected.featuredImage?.node.localFile)}
                    alt=""
                  ></GatsbyImage>
                </Square>
              )
            })}
          </Marquee>
        </Playground>
      </motion.div>
    </>
  )
}

export default SimpleVerticalMarquee
