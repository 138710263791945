import React, { useState, useEffect } from "react"

import { motion, AnimatePresence } from "framer-motion"

import Layout from "../components/Layout/Layout"
import SimpleVerticalMarquee from "../components/SimpleMarquee/Vertical/SimpleVerticalMarquee"
import MobileMarquee from "../components/SimpleMarquee/Mobile/MobileMarquee"

import VideoPlayer from "../components/VideoPlayer/VideoPlayer"
import Seo from "../components/Seo"

import { useWpHomepageSelectionNew } from "../hooks/useWpHomepage"
import useWindowSize from "../hooks/useWindowDimensions"
import "../styles/pages/index.scss"

const DURATION = 45
const Homepage = () => {
  const { width } = useWindowSize()
  const { mobile, desktop, desktopFeaturedImages } = useWpHomepageSelectionNew()
  const [isAlreadyVisited, setIsAlreadyVisited] = useState(false)
  const [cookieConsent, setCookiesConsent] = useState(false)
  useEffect(() => {
    // FIRST VISIT
    if (localStorage.getItem("alreadyVisited") === "true") {
      console.log("already visited")
      setIsAlreadyVisited(true)
    }
    // COOKIES
    if (localStorage.getItem("cookiesConsent") === "true") {
      setCookiesConsent(true)
    }
  }, [setCookiesConsent])

  const handleVideoClick = () => {
    localStorage.setItem("alreadyVisited", "true")
    setIsAlreadyVisited(true)
    setCookiesConsent(true)
  }
  return (
    <>
      <Seo title="Homepage" />

      {isAlreadyVisited ? (
        <Layout>
          {width < 768 ? (
            <MobileMarquee
              selection={mobile.homepage.selectedArtworks}
              imageGallery={desktopFeaturedImages.homepage.selectedArtworks}
            />
          ) : (
            <SimpleVerticalMarquee
              selection={desktop.homepage.selectedArtworks}
              imageGallery={desktopFeaturedImages.homepage.selectedArtworks}
              duration={DURATION}
            />
          )}
        </Layout>
      ) : (
        <Layout>
          <AnimatePresence>
            <motion.button
              id="video-button-container"
              onClick={() => handleVideoClick()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <VideoPlayer />
            </motion.button>
          </AnimatePresence>
        </Layout>
      )}
    </>
  )
}

export default Homepage
