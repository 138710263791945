import React, { useState, useEffect } from "react"
import Vimeo from "@u-wave/react-vimeo"
import "./VideoPlayer.scss"
const VideoPlayer = () => {
  const [isPaused, setIsPaused] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsPaused(true)
    }, 100)
  }, [])
  return (
    <section
      style={{ pointerEvents: "none" }}
      className="splash-video-container"
    >
      <Vimeo
        id="vimeo-player"
        // video="655802257"
        video="655802257"
        volume={0}
        paused={isPaused}
        autoplay
        background={true}
        responsive={true}
        loop={true}
        controls={false}
        dnt={true}
        //   onTimeUpdate={e => {
        //     console.log(e)
        //   }}
      />
      <div className="cookies-notice-container">
        <div className="cookies-notice-content-wrapper">
          <p className="sans-size">
            This website uses cookies to provide an optimal experience. <br />
            By using this website you agree to the use of cookies
          </p>
          <div className="cookies-cta-wrapper ">
            <span className="sans-size">Show website</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoPlayer
